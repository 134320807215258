import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import Layout from 'gatsby-theme-nurofen/src/components/Layout';
import { ISubNavItem } from 'gatsby-theme-nurofen/src/components/ArticleSubNav/model';
import bodyRenderer from 'gatsby-theme-nurofen/src/utils/bodyRenderer';

import bodySections from './bodySections';
import { ICampaignPageStarTrekProps } from './model';

import './CampaignPageStarTrek.scss';

const CampaignPageStarTrek: FC<ICampaignPageStarTrekProps> = ({
  pageContext: { relatedProducts: relatedProductsLinks },
  data,
}) => {
  const {
    page: {
      nodes: [
        {
          seoMetaTitle,
          seoMetaDescription,
          seoMetaKeywords,
          seoExternalHreflangs,
          seoContentType,
          lang,
          body,
          relatedProductsConfig,
          isArticleWithProducts,
        },
      ],
    },
    siteSettings,
    header,
    footer,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    carouselControls,
    relatedProducts,
    selectedProductsBody,
    pageLangs: { nodes: langs },
  } = data;

  const sectionAnchors: ISubNavItem[] = body
    .filter(({ properties }) => properties.sectionAnchor)
    .map(({ properties }) => ({
      anchorName: properties.sectionAnchor || '',
    }));

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        seoContentType,
      }}
      siteSettings={siteSettings}
      header={header}
      footer={footer}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      langSettings={{
        currentLang: lang,
        langs,
      }}
    >
      <div
        className={classnames(
          'campaign-page-star-trek',
          isArticleWithProducts ? 'article-with-products' : ''
        )}
      >
        {bodyRenderer(body, bodySections, {
          sectionAnchors,
          relatedProductsConfig,
          carouselControls,
          relatedProducts,
          relatedProductsLinks,
          selectedProductsBody,
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query(
    $lang: String!
    $link: String!
    $pageIdRegex: String
    $relatedProducts: [String]
    $selectedProductsBody: [String]
  ) {
    page: allCampaignPageStarTrek(filter: { lang: { eq: $lang }, link: { eq: $link } }) {
      nodes {
        lang
        ...FragmentSeo
        body {
          structure
          properties {
            ...FragmentPainBanner
            ...FragmentSideImageBanner
            ...FragmentSimpleListComponent
            ...FragmentTwoColumnListing
            ...FragmentPainBannerExtendTitle
            ...FragmentStarTrekBannerSmall
            ...FragmentsFacts
            ...FragmentsReports
            ...FragmentTextWithImage
            ...FragmentsPledge
            ...FragmentReadReport
            ...FragmentsTimeline
            ...FragmentRelatedProductsModifiedConfig
            bulletColor {
              ...FragmentColorProps
            }
            sectionLandmark
            text
          }
        }
        relatedProductsConfig {
          properties {
            ...FragmentRelatedProductsConfig
          }
        }
        isArticleWithProducts
      }
    }
    pageLangs: allUmbracoHome(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    relatedProducts: allUmbracoProduct(
      filter: { link: { in: $relatedProducts }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    selectedProductsBody: allUmbracoProduct(
      filter: { link: { nin: [$link], in: $selectedProductsBody }, lang: { eq: $lang } }
      limit: 6
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default CampaignPageStarTrek;
